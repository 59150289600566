

[data-feedback].error {
    color: red;
  }

[required]::-webkit-input-placeholder:after {
    content: " *";
    color: red;
}

[required]:-moz-placeholder:after {
    content: " *";
    color: red;  
}

[required]:-ms-input-placeholder:after {  
    content: " *";
    color: red;  
}

.PasswordBtn {
    color: yellow;
}