
@media only screen and (max-width: 600px) {
  .headerImg{
  max-height:200px !important;
  }  
}
@media only screen and (max-width: 780px) {
  .headerImg{
  max-height:280px !important;
  }    
}
@media only screen and (min-width: 781px) {
  .headerImg{
  max-height:280px !important;
  }    
}


.headerImg{
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: 0% 0%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
 
.headerImgLogo {
  position: absolute;
  top: 10px;
  left: 10px;
}

.headerTitleText {
  position: absolute;
  top: 20px;
  left: 60px;
  color: white;
  font-weight: bold;
}