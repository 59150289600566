body {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
  }
  
  a {
    text-decoration: none;
    color: inherit;
  }
  
  .containerOTP {
    display: flex;
    width: 100%;
    min-height: 10vh;
    height: 100%;
  }
  
  .inputStyle {
    width: 3rem !important;
    height: 3rem;
    margin: 0 1rem;
    font-size: 2rem;
  }
  
  
  .view {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  
  
  .card {
    min-width: 260px;
    padding: 1rem;
    border: none !important;
  }
  
  .card > form {
    display: flex;
    align-items: center;
  }
  
  .card > form > p {
    font-size: 1.5em;
    font-weight: bold;
  }
  
  .error {
    border: 1px solid red !important;
  }