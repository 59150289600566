input, textarea {
    margin-bottom: 10px;
  }
  
  [data-feedback] {
    margin-bottom: 10px;
    border: none !important;  
  }
  
  [data-feedback].error {
    color: red;
  }

  @media only screen and (min-width: 769px) and (max-width: 1281px) {
    .root {
      overflow-y: scroll;
    }
  }