.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.page-container{
  display: flex;
  flex-direction: column;
  min-height: 50vh;
}

.content-wrap{
  flex:1;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input[type=text], input[type=password], input[type=email]
{
    border:none;
    border-bottom: 1px solid lightgray;
}

.textboxIcon { 
  width: '20px';
  padding: '0px 0px 10px 0px';
  margin: '0px 5px 0px 0px';
}


.hi__control--menu-is-open { 
  border-color: red; 
}


.vodacomHeaderText {
  font-size: 30px;
  color: #4A4D4E;
}

.errorMessageTitle {
  font-size: 20px;
  color: red; 
  font-weight: bold; 
}

.popupMessageTitle {
  font-size: 20px;
  color: black; 
  font-weight: bold; 
} 

.popupConcernMessageTitle {
  font-size: 22px;
  color: #A8B400; 
  font-weight: bold; 
} 

.my-modal {  
  top:20%;
  text-align: center;
} 