.main-footer {
  display: flex;
  justify-content: space-between;
  color: white;
  background-color: #E60000;
  position: fixed;
  bottom: 0;
  width: 100%;
}


.main-footerOLD{
    color: white;
    background-color: #E60000;
    position: absolute;
    bottom: 0;
    display: flex;
    float: left;  
    left:0;
    right:0;
}

.footer-font{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    font-size: 30px;
    height: 50px;
}

.page-containerRight{
    display: flex;
    float: right;  
  }

  .button {
    background-color: #E60000; /* Green */
    border: none;
    color: #E60000;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
  }