.img-circular{
    width: 62px;
    height: 62px;
    background-size: cover;
    display: block;
    border-radius: 62px;
    -webkit-border-radius: 62px;
    -moz-border-radius: 62px;
   }

   #wrapper {
    display: flex;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  #second {
      padding-left: 10px;
  }

  .spanFont {
      font-size: 11px;
  }


  .borderless td, .borderless th {
    border: none;
}

